import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { useForm } from "../../components/useForm";

// llamados a endpoints
import { HttpService } from '../../service/HttpService';
import { ReporteZ as Endpoints } from '../../endpoints/reporte_z';

import { getUserData, configPermisosRW } from '../../service/AuthHelperMethods';
import ShowLoading from "../../components/ShowLoading/ShowLoading";
import swal from 'sweetalert';
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from 'primereact/inputnumber';
import { Stack, Divider } from '@mui/material';
import { format } from 'date-fns'
import './ReporteZ.scss'

const initialFValues = {
    fechaEmision: '',
    dFechaEmision: '',
    sNroReporte: '',
    nIdContribuyente: '',
    nIdUserCreate: '',
    nIdUserUpdate: '',
    nIdMoneda: '',
    deTotalBI: 0,
    nIdMaquinaFiscal: '',
    id: '',
    nEliminado: 0,
    ventas: [],
    debito: [],
    credito: []
};

const tipoVenta ={
    venta: 1,
    debito: 2,
    credito: 3,
}

const tipoInteraccion = {
    crear: 1,
    editar: 2,
    ver: 3,
}

const ReporteZ = () => {
    let emptyReporte = {
        fechaEmision: '',
        dFechaEmision: '',
        sNroReporte: '',
        nIdContribuyente: '',
        nIdUserCreate: '',
        nIdUserUpdate: '',
        nIdMoneda: '',
        nIdMaquinaFiscal: '',
        deTotalBI: 0,
        id: '',
        nEliminado: 0,
        ventas: [],
        debito: [],
        credito: []
    };

    const emptyVenta = {
        nIdTipoVenta: 0,
        sFacturaDesde: 0,
        sFacturaHasta: 0,
        nIdImpuesto: 0,
        deTotalImpuestos: 0,
        deTotalBI: 0,
        nNroOperacion: 0,
    }

	let location = useLocation();
	const [acceso, setAcceso] = useState("");
    const toast = useRef(null);
    const [httpService] = useState(new HttpService());
    const [endpoints] = useState(new Endpoints());
    const [ReporteZDialog, setReporteZDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [userData] = useState(getUserData() || null);
    const [reportes, setReportes] = useState([]);
    const [reporte, /* setTProducto */] = useState(emptyReporte);
	const [loadingDialog, setLoadingDialog] = useState(false);

    // select for delete
    const [idReporte, setIdReporte] = useState(null);
    
    // Tipo de interacción 1: crear, 2: editar,  3: leer
    const [tipo, setTipo] = useState(1);

    // Opciones para los select
    const [MonedaBsOptions, setMonedaBsOptions] = useState([]);
    const [maquinasFiscalesOptions, setMaquinasFiscalesOptions] = useState([]);
    const [impuestosOptions, setImpuestosOptions] = useState([]);

    // Multiformulario reporte Z
    const [ventas, setVentas] = useState([]);
    const [debito, setDebito] = useState([]);
    const [credito, setCredito] = useState([]);

    useEffect(() => {
		setLoadingDialog(true);
		let acc = configPermisosRW(location, userData);
		setAcceso(acc);
        httpService.getOptionSelect("lista_impuestos").then((data) => setImpuestosOptions(data));
        httpService.getOptionSelect("lista_maquinasFiscales", userData.contribuyente).then(data => {setMaquinasFiscalesOptions(data);}).catch(err => {});
        httpService.getOptionSelect("lista_SiBolivar").then((data) => setMonedaBsOptions(data));
        endpoints.getReporteZList(userData.contribuyente)
		.then(data => { setLoadingDialog(false); if (data.error) toast.current.show({ severity: 'error', summary: 'Error', detail: data.message, life: 5000 }); else  setReportes(data.data)})
		.catch(err => { setLoadingDialog(false); console.log(err); });
    }, [httpService, location, userData, endpoints]);

    const [ButtonDisabled, setButtonDisabled] = useState(false);
    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ("fechaEmision" in fieldValues) temp.fechaEmision = fieldValues.fechaEmision ? "" : "Campo Requerido.";
        if ("sNroReporte" in fieldValues) temp.sNroReporte = fieldValues.sNroReporte ? "" : "Campo Requerido.";

        setErrors({
            ...temp,
        });

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };
    const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(initialFValues, true, validate);

    const handleSubmit = async (e) => {
        e.preventDefault();
        tipo === tipoInteraccion.crear ? registrarReporte() : actualizarReporte();
    };

    const registrarReporte = () => {
        try {
            setSubmitted(true);
                setButtonDisabled(true);
                values.nIdContribuyente = userData.contribuyente;
                values.nIdUserCreate = userData.idUsuario;
                values.nIdUserUpdate = userData.idUsuario;
                values.dFechaEmision = format(values.fechaEmision, "yyyy-MM-dd HH:MM:SS")
                endpoints.agregarReporteZ({reporteCab: values, reporteItems: [...ventas, ...debito, ...credito] })
                    .then((res) => {
                        setButtonDisabled(false);
                        if (res.error) {
                            let temp = { ...errors };
                            setErrors({
                                ...temp,
                            });
                            swal({ title: "Ups...", text: res.message, icon: "error" });
                        } else {
                            //registro exitoso
                            swal({ title: "Éxito", text: res.message, icon: "success" });
                            setReporteZDialog(false);
                            endpoints.getReporteZList(userData.contribuyente).then(data => setReportes(data.data));
                            resetForm();
                            resetMultiForm();
                        }
                    })
                    .catch((err, res) => {
                        const error = {...err}
                        
                        setButtonDisabled(false);
                        swal({ title: "Ups...", text: error.response.data.message, icon: "error" });
                    });
                setButtonDisabled(false);
        } catch (error) {
            console.log(error);
        }
    }

    const actualizarReporte = () => {
        try {
            setSubmitted(true);
                setButtonDisabled(true);
                const finalValue = {...values};
                finalValue.nIdUserUpdate = userData.idUsuario;
                finalValue.dFechaEmision = format(values.fechaEmision, "yyyy-MM-dd HH:MM:SS")
                delete finalValue.items;
                console.log(finalValue);
                
                endpoints.actualizarReporteZ({
                    reporteCab: finalValue, 
                    reporteItems: [...ventas, ...debito, ...credito] 
                }).then((res) => {
                    setButtonDisabled(false);
                    console.log(res.error);
                    
                    if (res.error) {
                        let temp = { ...errors };
                        setErrors({
                            ...temp,
                        });
                        swal({ title: "Ups...", text: res.message, icon: "error" });
                    } else {
                        //registro exitoso
                        swal({ title: "Éxito", text: res.message, icon: "success" });
                        setReporteZDialog(false);
                        endpoints.getReporteZList(userData.contribuyente).then(data => setReportes(data.data));
                        resetForm();
                        resetMultiForm();
                    }})
                .catch((err) => {
                    swal({ title: "Ups...", text: err.message, icon: "error" });
                    setButtonDisabled(false);
                });
        } catch (error) {
            console.log(error);
        }
    }

    const openNew = () => {
        setValues(emptyReporte);
        setTipo(1);
        setSubmitted(false);
        setReporteZDialog(true);
    }

    const editReporte = (reporte) => {
        const nIdReporte = reporte.nIdReporte;
        const IdContribuyente = userData.contribuyente;
        endpoints.getReporteZDetails({nIdReporte, IdContribuyente}).then((data) => {setVentaData(data.data)});
        setTipo(2);
        setReporteZDialog(true);
    }

    const setVentaData = (val) => {
        const { items } = val;
        const newVal = new Date(`${val.dFechaEmision}T00:00:00`);
        setValues({...val, fechaEmision: newVal });
        
        const ventas = items.filter((item) => item.nIdTipoVenta === tipoVenta.venta);
        setVentas([...ventas]);
        const debito = items.filter((item) => item.nIdTipoVenta === tipoVenta.debito);
        setDebito([...debito]);
        const credito = items.filter((item) => item.nIdTipoVenta === tipoVenta.credito);
        setCredito([...credito]);
    };
    
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button
                    label="Nuevo"
                    icon="pi pi-plus"
                    className="p-button-success p-mr-2"
                    onClick={openNew} disabled={ acceso && acceso === "W" ? false : true } 
                />
            </React.Fragment>
        )
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Reporte Z</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const nroReporte = (rowData) => {
        return (
            <>
                {rowData.sNroReporte}
            </>
        );
    }

    const maquina = (rowData) => {
        return (
            <>
                {rowData.nIdMaquinaFiscal}
            </>
        );
    }

    const emision = (rowData) => {
        return (
            <>
                {rowData.dFechaEmision}
            </>
        );
    }

    const getMoneda = (data) => {
        const index = MonedaBsOptions.findIndex(item => item.id === data.nIdMoneda);
        return index !== -1 ? MonedaBsOptions[index].title : 'Bs'
    }

    const moneda = (rowData) => {
        return (
            <>
                {getMoneda(rowData)}
            </>
        );
    }

    const total = (rowData) => {
        return (
            <>
                {rowData.deTotalBI}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button 
                    icon="pi pi-search" 
                    className="p-button-rounded p-button-success p-button-sm p-mr-2"
                    tooltip={"Ver detalles"}
                    tooltipOptions={{position: 'top'}}
                    onClick={() => verDetalles(rowData)} />
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2"
                    onClick={() => editReporte(rowData)}
                    disabled={ acceso && acceso === "W" ? false : true }
                />
                <Button 
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-2"
                    onClick={() => confirmDeleteSelected(rowData)}
                    disabled={ acceso && acceso === "W" ? false : true }
                />
            </div>
        );
    }

    const [deleteReportesDialog, setDeleteReportesDialog] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);



    const hideDeleteReportesDialog = () => {
        setDeleteReportesDialog(false);
    }


    const confirmDeleteSelected = (rowData) => {
        setIdReporte(rowData.nIdReporte);
        setDeleteReportesDialog(true);
    }

    const deleteSelectedReportes = async (e) => {
        e.preventDefault();
        const nIdReporte = idReporte;
        const nIdContribuyente = userData.contribuyente;
        endpoints.deleteReporte({nIdReporte, nIdContribuyente})
            .then((res) => {
                if (res.error) {
                    let temp = { ...errors };
                    if (res.message.indexOf("Email") >= 0) {
                        temp.txtEmail = res.message;
                    }
                    setErrors({
                        ...temp,
                    });
                    toast.current.show({ severity: 'error', summary: 'Error', detail: res.message, life: 3000 });
                } else {
                    //Eliminacion exitosa
                    toast.current.show({ severity: 'success', summary: 'Exito', detail: res.message, life: 3000 });
                    endpoints.getReporteZList(userData.contribuyente).then(data => setReportes(data.data));
                    setDeleteReportesDialog(false);
                }
            })
            .catch((err) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: err.message, life: 3000 });
            });
    }

    const deleteReporteDialogFooter = (
        <>
            <Button
                label="No"
                icon="pi pi-times"
                className="p-button-text"
                onClick={hideDeleteReportesDialog}
            />
            <Button
                label="Si"
                icon="pi pi-check"
                className="p-button-text"
                onClick={deleteSelectedReportes}
            />
        </>
    );

    // resetear campos dinamicos
    const resetMultiForm = () => {
        setVentas([]);
        setDebito([]);
        setCredito([]);
    };

    const hideReporteZDialog = () => {
        resetMultiForm();
        setReporteZDialog(false);
    };
    
    const ReporteZDialogFooter = (
        <>
            {tipo !== tipoInteraccion.ver?
            (<>
                <Button
                    label="Cancel" 
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={hideReporteZDialog} />
                <Button
                    label="Guardar"
                    icon="pi pi-check"
                    className="p-button-text"
                    disabled={ButtonDisabled}
                    onClick={handleSubmit}
                />
            </>): ''}
        </>
    );

    // ver detalles de reporte
    const verDetalles = (reporte) => {
        const nIdReporte = reporte.nIdReporte;
        const IdContribuyente = userData.contribuyente;
        endpoints.getReporteZDetails({nIdReporte, IdContribuyente}).then((data) => {setVentaData(data.data)});
        setTipo(3);
        setReporteZDialog(true);
    }

    // Añadir ventas a array
    const añadirVenta = (type) => {
        let newArray = []
        switch (type) {
            case tipoVenta.venta:
                emptyVenta.nIdTipoVenta = tipoVenta.venta;
                newArray = [...ventas, emptyVenta];
                setVentas(newArray);
            break;
            case tipoVenta.debito:

                emptyVenta.nIdTipoVenta = tipoVenta.debito;
                newArray = [...debito, emptyVenta]
                setDebito(newArray);
            break;
            case tipoVenta.credito:

                emptyVenta.nIdTipoVenta = tipoVenta.credito;
                newArray = [...credito, emptyVenta];
                setCredito(newArray);
                break;
        
            default:
                break;
        }
    }

    // Eliminar Venta de array
    const eliminarVenta = (type, index) => {
        let newArray = []
        switch (type) {
            case tipoVenta.venta:
                newArray = [...ventas];
                newArray.splice(index, 1);
                setVentas(newArray);
                
            break;
            case tipoVenta.debito:
                newArray = [...debito];
                newArray.splice(index, 1);
                setDebito(newArray);
            break;
            case tipoVenta.credito:
                newArray = [...credito];
                newArray.splice(index, 1);
                setCredito(newArray);
                break;
        
            default:
                break;
        }
    }

    // Handle input para array de ventas
    const handleInputArray = (e, type, index) => {
        let newArray = []
        const name = e.originalEvent.target.name || e.target.name;
        switch (type) {
            case tipoVenta.venta:
                newArray = [...ventas];
                newArray[index][name] = e.value;
                setVentas(newArray);
                
                break;
            case tipoVenta.debito:
                newArray = [...debito];
                newArray[index][name] = e.value;
                setDebito(newArray);

                break;
            case tipoVenta.credito:
                newArray = [...credito];
                newArray[index][name] = e.value;
                setCredito(newArray);

                break;
            default:
                break;
        }
    }

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate} ></Toolbar>
                    <DataTable 
                        ref={dt} 
                        value={reportes}
                        dataKey="nIdReporte" 
                        paginator 
                        rows={10} 
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} reportes"
                        globalFilter={globalFilter} 
                        emptyMessage="No hay Reporte Z." 
                        header={header} 
                    >
                        <Column field="dFechaEmision" header="N° Reporte" sortable body={nroReporte}></Column>
                        <Column field="sNroReporte" header="Maquina fiscal" sortable body={maquina}></Column>
                        <Column field="estatus" header="Fecha emisión"  body={emision}    ></Column>
                        <Column field="estatus" header="Moneda"  body={moneda}    ></Column>
                        <Column field="estatus" header="Total Ventas"  body={total}    ></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog
                        visible={deleteReportesDialog}
                        style={{ width: '450px' }}
                        header="Confirm"
                        modal
                        onHide={hideDeleteReportesDialog}
                        footer={deleteReporteDialogFooter}
                    >
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {reporte && <span>Esta Seguro de Eliminar el reporte seleccionado?</span>}
                        </div>
                    </Dialog>

                    <Dialog
                        visible={ReporteZDialog}
                        maximizable
                        modal
                        style={{ width: '50vw' }}
                        header="Carga Reporte Z"
                        className="p-fluid"
                        onHide={hideReporteZDialog}
                        footer={ReporteZDialogFooter}
                    >
                        <InputText type="hidden" name="id" value={values.id} onChange={handleInputChange} />
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="fechaEmision">Fecha</label>
                                <Calendar
                                    showIcon
                                    dateFormat="dd/m/yy"
                                    id="fechaEmision"
                                    name="fechaEmision"
                                    locale="es"
                                    value={values.fechaEmision}
                                    onChange={handleInputChange}
                                    className={classNames({ 'p-invalid': submitted && !values.fechaEmision })}
                                    disabled={tipo === tipoInteraccion.ver}
                                />
                                {submitted && !values.fechaEmision && <small className="p-invalid">{errors.fechaEmision}</small>}
                            </div>
                            <div className="p-field p-col">
                                <label htmlFor="nIdMoneda">Moneda</label>
                                <Dropdown
                                    id="nIdMoneda"
                                    name="nIdMoneda"
                                    value={values.nIdMoneda}
                                    onChange={handleInputChange}
                                    optionValue="id"
                                    optionLabel="title"
                                    options={MonedaBsOptions}
                                    placeholder="Selecciona uno"
                                    className={classNames({ 'p-invalid': submitted && !values.nIdMoneda })}
                                    disabled={tipo === tipoInteraccion.ver}
                                ></Dropdown>
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="sNroReporte">Nro Reporte</label>
								<InputText
                                    name="sNroReporte"
                                    value={values.sNroReporte}
                                    onChange={handleInputChange}
                                    className={classNames({ 'p-invalid': submitted && !values.sNroReporte })}
                                    disabled={tipo === tipoInteraccion.ver}
                                />
								{submitted && !values.sNroReporte && <small className="p-invalid">{errors.sNroReporte}</small>}
                            </div>
                            <div className="p-field p-col">
                                <label htmlFor="nIdMaquinaFiscal">Máquina Fiscal</label>
                                <Dropdown
                                    id="nIdMaquinaFiscal"
                                    name="nIdMaquinaFiscal"
                                    value={values.nIdMaquinaFiscal}
                                    onChange={handleInputChange}
                                    optionValue="id"
                                    optionLabel="title"
                                    options={maquinasFiscalesOptions}
                                    placeholder="Selecciona uno"
                                    disabled={!userData.usaMF || tipo === tipoInteraccion.ver}
                                    className={classNames({ 'p-invalid': submitted && userData.usaMF && !values.nIdMaquinaFiscal })}
                                ></Dropdown>
                                {submitted && !values.nIdMaquinaFiscal && <small className="p-invalid">{errors.nIdMaquinaFiscal==="0" ? "" : errors.nIdMaquinaFiscal}</small>}
                            </div>
                        </div>
                        <div className="p-field">
                            <label htmlFor="deTotalBI">Total Ventas</label>
                            <InputNumber
                                name="deTotalBI"
                                mode="decimal"
                                minFractionDigits={2}
                                value={values.deTotalBI}
                                onChange={(e) => setValues({...values, deTotalBI: e.value})}
                                className={classNames({ 'p-invalid': submitted && !values.deTotalBI })}
                                disabled={tipo === tipoInteraccion.ver}
                            />
                            {submitted && !values.deTotalBI && <small className="p-invalid">{errors.deTotalBI}</small>}
                        </div>
                        <Divider className='my-3'></Divider>
                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{ justifyContent: "space-between", alignItems: "center" }}
                            className='mb-3'
                        >
                            <div className='h5'>Ventas:</div>
                            {tipo !== tipoInteraccion.ver? (
                                <div className='h5 text-success pointer' onClick={() => añadirVenta(tipoVenta.venta)}>Añadir +</div>
                            ): ''}
                        </Stack>
                        {ventas.map((element, i) => {
                            return (
                            <div key={'ventas'+i}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    sx={{ justifyContent: "space-around", alignItems: "center" }}
                                >
                                    <div className='h6 pl-1'>Venta {i+1}</div>
                                    <div className='h6 pointer delete' onClick={() => eliminarVenta(tipoVenta.venta, i)}>Eliminar -</div>
                                    
                                </Stack>
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor={`sFacturaDesde`}>Factura Desde</label>
                                        <InputNumber
                                            name="sFacturaDesde"
                                            mode="decimal" 
                                            minFractionDigits={0} 
                                            value={ventas[i].sFacturaDesde} 
                                            onChange={(e) => handleInputArray(e, tipoVenta.venta, i)} 
                                            required 
                                            className={classNames({ 'p-invalid': submitted && !ventas[i].sFacturaDesde })}
                                            disabled={tipo === tipoInteraccion.ver}
                                        />
                                        {submitted && !ventas[i].sFacturaDesde && <small className="p-invalid">{errors.sFacturaDesde}</small>}
                                    </div>
                                    <div className="p-field p-col">
                                        <label htmlFor="sFacturaHasta">Factura Hasta</label>
                                        <InputNumber 
                                            name="sFacturaHasta" 
                                            mode="decimal" 
                                            minFractionDigits={0} 
                                            value={ventas[i].sFacturaHasta} 
                                            onChange={(e) => handleInputArray(e, tipoVenta.venta, i)} 
                                            required 
                                            className={classNames({ 'p-invalid': submitted && !ventas[i].sFacturaHasta })}
                                            disabled={tipo === tipoInteraccion.ver}
                                        />
                                        {submitted && !ventas[i].sFacturaHasta && <small className="p-invalid">{errors.txtFacturaHastaRZ}</small>}
                                    </div>
                                </div>
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="nNroOperacion">Número de operación</label>
                                        <InputNumber 
                                            name="nNroOperacion"
                                            value={ventas[i].nNroOperacion} 
                                            onChange={(e) => handleInputArray(e, tipoVenta.venta, i)} 
                                            required 
                                            className={classNames({ 'p-invalid': submitted && !ventas[i].nNroOperacion  && ventas[i].nNroOperacion > 0 })}
                                            disabled={tipo === tipoInteraccion.ver}
                                        />
                                        {submitted && !ventas[i].nNroOperacion && <small className="p-invalid">{errors.nNroOperacion}</small>}
                                    </div>
                                    <div className="p-field p-col">
                                        <label htmlFor="deTotalBI">Total Base Imponible</label>
                                        <InputNumber 
                                            name="deTotalBI" 
                                            mode="decimal" 
                                            minFractionDigits={2} 
                                            value={ventas[i].deTotalBI} 
                                            onChange={(e) => handleInputArray(e, tipoVenta.venta, i)} 
                                            required 
                                            className={classNames({ 'p-invalid': submitted && !ventas[i].deTotalBI })}
                                            disabled={tipo === tipoInteraccion.ver}
                                        />
                                        {submitted && !ventas[i].deTotalB && <small className="p-invalid">{errors.deTotalBI}</small>}
                                    </div>
                                </div>
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="nIdImpuesto">Impuesto</label>
                                        <Dropdown 
                                            id='nIdImpuesto'
                                            name="nIdImpuesto" 
                                            value={ventas[i].nIdImpuesto} 
                                            onChange={(e) => handleInputArray(e, tipoVenta.venta, i)} 
                                            optionValue="id" 
                                            optionLabel="title" 
                                            options={impuestosOptions} 
                                            placeholder="Selecciona uno" 
                                            disabled={tipo === tipoInteraccion.ver || !userData.usaMF} 
                                            className={classNames({ 'p-invalid': submitted && userData.usaMF && !ventas[i].nIdImpuesto })}
                                        ></Dropdown>
                                        {submitted && !ventas[i].nIdImpuesto && <small className="p-invalid">{errors.nIdImpuesto==="0" ? "" : errors.nIdImpuesto}</small>}
                                    </div>
                                    <div className="p-field p-col">
                                        <label htmlFor="deTotalImpuestos">Total Impuesto</label>
                                        <InputNumber 
                                            id="deTotalImpuestos"
                                            name="deTotalImpuestos" 
                                            mode="decimal" 
                                            minFractionDigits={2} 
                                            value={ventas[i].deTotalImpuestos} 
                                            onChange={(e) => handleInputArray(e, tipoVenta.venta, i)} 
                                            required 
                                            className={classNames({ 'p-invalid': submitted && !ventas[i].deTotalImpuestos })}
                                            disabled={tipo === tipoInteraccion.ver}
                                        />
                                        {submitted && !ventas[i].deTotalImpuestos && <small className="p-invalid">{errors.deTotalImpuestos}</small>}
                                    </div>
                                </div>
                            </div>
                        )})}
                        <Divider className='my-3'></Divider>
                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{ justifyContent: "space-between", alignItems: "center" }}
                        >
                            <div className='h5'>Nota de Débito:</div>
                            {tipo !== tipoInteraccion.ver? (
                                <div className='h5 text-success pointer' onClick={() => añadirVenta(tipoVenta.debito)}>Añadir +</div>
                            ): ''}
                        </Stack>
                        {debito.map((element, i) => {
                            return (
                            <div key={'debito'+i}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    sx={{
                                        justifyContent: "space-around",
                                        alignItems: "center",
                                    }}
                                    className='mb-3'
                                >
                                    <div className='h6 pl-1'> Debito {i+1}</div>
                                    <div className='h6 pointer deleteLink' onClick={() => eliminarVenta(tipoVenta.debito, i)}>Eliminar -</div>
                                </Stack>
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="sDebitoFacturaDesde">Nota de Débito Desde</label>
                                        <InputNumber
                                            id='sDebitoFacturaDesde'
                                            name="sDebitoFacturaDesde"
                                            mode="decimal" 
                                            minFractionDigits={0} 
                                            value={debito[i].sFacturaDesde} 
                                            onChange={(e) => handleInputArray(e, tipoVenta.debito, i)} 
                                            required 
                                            className={classNames({ 'p-invalid': submitted && !debito[i].sFacturaDesde })}
                                            disabled={tipo === tipoInteraccion.ver}
                                        />
                                        {submitted && !debito[i].sFacturaDesde && <small className="p-invalid">{errors.txtFacturaDesdeRZ}</small>}
                                    </div>
                                    <div className="p-field p-col">
                                        <label htmlFor="sFacturaHasta">Nota de Débito Hasta</label>
                                        <InputNumber 
                                            name="sFacturaHasta" 
                                            mode="decimal" 
                                            minFractionDigits={0} 
                                            value={debito[i].sFacturaHasta} 
                                            onChange={(e) => handleInputArray(e, tipoVenta.debito, i)} 
                                            required 
                                            className={classNames({ 'p-invalid': submitted && !debito[i].sFacturaHasta })}
                                            disabled={tipo === tipoInteraccion.ver}
                                        />
                                        {submitted && !debito[i].sFacturaHasta && <small className="p-invalid">{errors.txtFacturaHastaRZ}</small>}
                                    </div>
                                </div>
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="nNroOperacion">Número de operación</label>
                                        <InputNumber 
                                            name="nNroOperacion"
                                            value={debito[i].nNroOperacion}
                                            onChange={(e) => handleInputArray(e, tipoVenta.debito, i)}
                                            required
                                            className={classNames({ 'p-invalid': submitted && !debito[i].nNroOperacion && debito[i].nNroOperacion > 0 })}
                                            disabled={tipo === tipoInteraccion.ver}
                                        />
                                        {submitted && !debito[i].nNroOperacion && <small className="p-invalid">{errors.nNroOperacion}</small>}
                                    </div>
                                    <div className="p-field p-col">
                                        <label htmlFor="deTotalBI">Total Base Imponible</label>
                                        <InputNumber 
                                            name="deTotalBI"
                                            mode="decimal"
                                            minFractionDigits={2}
                                            value={debito[i].deTotalBI}
                                            onChange={(e) => handleInputArray(e, tipoVenta.debito, i)}
                                            required
                                            className={classNames({ 'p-invalid': submitted && !debito[i].deTotalBI })}
                                            disabled={tipo === tipoInteraccion.ver}
                                        />
                                        {submitted && !debito[i].deTotalB && <small className="p-invalid">{errors.deTotalBI}</small>}
                                    </div>
                                </div>
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="nIdImpuesto">Impuesto</label>
                                        <Dropdown 
                                            id="nIdImpuesto" 
                                            name="nIdImpuesto" 
                                            value={debito[i].nIdImpuesto} 
                                            onChange={(e) => handleInputArray(e, tipoVenta.debito, i)} 
                                            optionValue="id" 
                                            optionLabel="title" 
                                            options={impuestosOptions} 
                                            placeholder="Selecciona uno" 
                                            disabled={tipo === tipoInteraccion.ver || !userData.usaMF} 
                                            className={classNames({ 'p-invalid': submitted && userData.usaMF && !debito[i].nIdImpuesto })}
                                        ></Dropdown>
                                        {submitted && !debito[i].nIdImpuesto && <small className="p-invalid">{errors.nIdImpuesto==="0" ? "" : errors.nIdImpuesto}</small>}
                                    </div>
                                    <div className="p-field p-col">
                                        <label htmlFor="deTotalImpuestos">Total Impuesto</label>
                                        <InputNumber 
                                            name="deTotalImpuestos"
                                            mode="decimal"
                                            minFractionDigits={2}
                                            value={debito[i].deTotalImpuestos}
                                            onChange={(e) => handleInputArray(e, tipoVenta.debito, i)}
                                            required
                                            className={classNames({ 'p-invalid': submitted && !debito[i].deTotalImpuestos })}
                                            disabled={tipo === tipoInteraccion.ver}
                                        />
                                        {submitted && !debito[i].deTotalImpuestos && <small className="p-invalid">{errors.deTotalImpuestos}</small>}
                                    </div>
                                </div>
                            </div>
                        )})}
                        <Divider className='my-3'></Divider>
                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{ justifyContent: "space-between", alignItems: "center" }}
                        >
                            <div className='h5'>Nota de Crédito:</div>
                            {tipo !== tipoInteraccion.ver? (
                                <div className='h5 text-success pointer' onClick={() => añadirVenta(tipoVenta.credito)}>Añadir +</div>
                            ): ''}
                        </Stack>
                        {credito.map((element, i) => {
                            return (
                            <div key={'credito'+i}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    sx={{ justifyContent: "space-around", alignItems: "center" }}
                                    className='mb-3'
                                >
                                    <div className='h6 pl-1'>Credito {i+1}</div>
                                    <div className='h6 pointer deleteLink' onClick={() => eliminarVenta(tipoVenta.credito, i)}>Eliminar -</div>
                                </Stack>
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="sFacturaDesde">Nota de Crédito Desde</label>
                                        <InputNumber 
                                            id="sFacturaDesde" 
                                            name="sFacturaDesde" 
                                            mode="decimal" 
                                            minFractionDigits={0} 
                                            value={credito[i].sFacturaDesde} 
                                            onChange={(e) => handleInputArray(e, tipoVenta.credito, i)} 
                                            required 
                                            className={classNames({ 'p-invalid': submitted && !credito[i].sFacturaDesde })}
                                            disabled={tipo === tipoInteraccion.ver}
                                        />
                                        {submitted && !credito[i].sFacturaDesde && <small className="p-invalid">{errors.txtFacturaDesdeRZ}</small>}
                                    </div>
                                    <div className="p-field p-col">
                                        <label htmlFor="sFacturaHasta">Nota de Crédito Hasta</label>
                                        <InputNumber 
                                            id="sFacturaHasta"
                                            name="sFacturaHasta"
                                            mode="decimal"
                                            minFractionDigits={0}
                                            value={credito[i].sFacturaHasta} 
                                            onChange={(e) => handleInputArray(e, tipoVenta.credito, i)}
                                            required className={classNames({ 'p-invalid': submitted && !credito[i].sFacturaHasta })}
                                            disabled={tipo === tipoInteraccion.ver}
                                        />
                                        {submitted && !credito[i].sFacturaHasta && <small className="p-invalid">{errors.txtFacturaHastaRZ}</small>}
                                    </div>
                                </div>
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="nNroOperacion">Número de operación</label>
                                        <InputNumber 
                                            name="nNroOperacion"
                                            value={credito[i].nNroOperacion} 
                                            onChange={(e) => handleInputArray(e, tipoVenta.credito, i)} 
                                            required 
                                            className={classNames({ 'p-invalid': submitted && !credito[i].nNroOperacion && credito[i].nNroOperacion > 0})}
                                            disabled={tipo === tipoInteraccion.ver}
                                        />
                                        {submitted && !credito[i].nNroOperacion && <small className="p-invalid">{errors.nNroOperacion}</small>}
                                    </div>
                                    <div className="p-field p-col">
                                        <label htmlFor="deTotalBI">Total Base Imponible</label>
                                        <InputNumber 
                                            name="deTotalBI" 
                                            mode="decimal" 
                                            minFractionDigits={2} 
                                            value={credito[i].deTotalBI} 
                                            onChange={(e) => handleInputArray(e, tipoVenta.credito, i)} 
                                            required 
                                            className={classNames({ 'p-invalid': submitted && !credito[i].deTotalBI })}
                                            disabled={tipo === tipoInteraccion.ver}
                                        />
                                        {submitted && !credito[i].deTotalBI && <small className="p-invalid">{errors.deTotalBI}</small>}
                                    </div>
                                </div>
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="nIdImpuesto">Impuesto</label>
                                        <Dropdown 
                                            id="nIdImpuesto"
                                            name="nIdImpuesto"
                                            value={credito[i].nIdImpuesto}
                                            onChange={(e) => handleInputArray(e, tipoVenta.credito, i)}
                                            optionValue="id" 
                                            optionLabel="title" 
                                            options={impuestosOptions}
                                            placeholder="Selecciona uno" 
                                            disabled={tipo === tipoInteraccion.ver || !userData.usaMF}
                                            className={classNames({ 'p-invalid': submitted && userData.usaMF && !credito[i].nIdImpuesto })}
                                        ></Dropdown>
                                        {submitted && !credito[i].nIdImpuesto && <small className="p-invalid">{errors.nIdImpuesto==="0" ? "" : errors.nIdImpuesto}</small>}
                                    </div>
                                    <div className="p-field p-col">
                                        <label htmlFor="deTotalImpuestos">Total Impuesto</label>
                                        <InputNumber
                                            name="deTotalImpuestos"
                                            mode="decimal"
                                            minFractionDigits={2}
                                            value={credito[i].deTotalImpuestos}
                                            onChange={(e) => handleInputArray(e, tipoVenta.credito, i)}
                                            required
                                            className={classNames({ 'p-invalid': submitted && !credito[i].deTotalImpuestos })}
                                            disabled={tipo === tipoInteraccion.ver}
                                        />
                                        {submitted && !credito[i].deTotalImpuestos && <small className="p-invalid">{errors.deTotalImpuestos}</small>}
                                    </div>
                                </div>
                            </div>
                        )})}
                    </Dialog>
                </div>
            </div>
			<div className="p-col-12">
                <div>
                    <ShowLoading data={loadingDialog} />
                </div>
            </div>
        </div>
    );
}

export default ReporteZ;